import React from 'react';
import cl from './Typography.module.scss';
import classnames from 'classnames';

export const Typography = (props) => { 

  const {
    As = 'span',
    className,
    children,
    color = 'black',
    bold = false,
    underline = false,
    uppercase = false,
    font = 'montserrat',
    weight,
    size,    
    style,
    refer,
    id,
  } = props;

  const classes = classnames(
    className,
    cl[`font-${font}`],
    cl[color],
    cl[`w-${weight?weight:(size>=20 && size<=120)?700:400}`],    
    { [cl.bold]: bold },
    { [cl.underline]: underline },
    { [cl.uppercase]: uppercase },

  );

  return (
    <As ref={refer} style={{...style, fontSize: `${size}px`}} className={classes} id={id}>
      {children}
    </As>
  );
};
