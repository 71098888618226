import { Suspense, useMemo, useEffect } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import styles from './BirdModel.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getBirdLoaded, setLoaded } from '../../store/birdLoader';

const AnimationModel = () => {
  const glb = useLoader(GLTFLoader, "/gang.gltf", (loader) => {    
  });
  const dispatch = useDispatch();

  
  useEffect(()=>{
    dispatch(setLoaded);
  },[glb.animations, glb.scene]);

  const animation = useMemo(() => {
    const mixer = new THREE.AnimationMixer(glb.scene);
    const clips = glb.animations;
    const action = mixer.clipAction(clips[0]);
    const action1 = mixer.clipAction(clips[7]);
    const action2 = mixer.clipAction(clips[4]); 

    action.setLoop(THREE.LoopOnce, 1);
    action.clampWhenFinished = true;
    action.enabled = true;

    action1.setLoop(THREE.LoopOnce, 1);
    action1.clampWhenFinished = true;
    action1.enabled = true;

    action.play();
    action1.play();

    mixer.addEventListener("finished", () => action2.play());

    return { mixer, action };
  }, [glb.animations, glb.scene]);

  useFrame((state, delta) => animation.mixer.update(delta));

  return (
    <mesh scale={[-1, 1, 1]} position={[0.1, -1.7, 1.2]} rotation={[0, 0.5, 0]}>
      <primitive object={glb.scene} />;
    </mesh>
  );
};

export const BirdModel = () => {
  return(
      <div className={styles.model}>
        <Canvas className={styles.canvas}>
          <ambientLight intensity={0.3} />          
          <directionalLight castShadow={false} intensity={4} position={[5, 10, 10]} />
          <Suspense fallback={null}>
            <AnimationModel />
          </Suspense>
        </Canvas>
      </div>    
  );
}