import React, { useState, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Input.module.sass';
import classnames from 'classnames';

import Eye from '../../assets/eye.svg';
import EyeClosed from '../../assets/eyeclosed.svg';
import iconError from '../../assets/iconError.png';




export const Input = ({ type, disabled = false, value, setValue, validationScheme, showErrPerOneChange = true, errorMessage, setErrorMessage, placeholder }) => {

	const [showPass, setShowPass] = useState(false);	

	const toggleShowPass = () => {
		setShowPass(prev=>!prev);
	}

	const handleChange = (event) => {
		const elementValue = event.target.value;		

		try{			
			validationScheme(elementValue);
		}catch(err){

			if (showErrPerOneChange && err?.name === "ValidationResponse"){
				setErrorMessage({resolvedFields: err.resolvedFields, rejectedFields: err.rejectedFields});	
			}

		}finally{
			if (setValue) setValue(elementValue);
		}
	}

	return (
		<div className={classnames(styles.main_wrap)}>
			<div className={classnames(styles.input_wrap, {
				[styles["error"]]: Boolean(errorMessage)
			})}>
				<input
					type={showPass?"input":type}
					className={classnames(styles.input)}
					placeholder={placeholder}
					onChange={handleChange}
					onClick={handleChange}
					value={value?value:""}
					autoComplete="none"
					disabled={disabled}
				/>
				{type === "password" && <img src={showPass ? Eye : EyeClosed} alt="eye" className={classnames(styles.eye)} onClick={toggleShowPass}/>}				
			</div>
{/*			<div className={classnames(styles.wrap_err)}>
			{Boolean(errorMessage) && (
				<>
					<img src={iconError} />				
					<div className={classnames(styles.message_err)}>{errorMessage}</div>
				</>				
			)}
			</div>*/}
		</div>
	);
}