export const RULES = {
	questions_sizes : [
	    {
	      outMinsize: 20,
	      outMaxsize: 40,	    	
	      minWidth:320,
	      maxWidth:743,        
	      minSize:20,
	      maxSize:40
	    }    
	],
	descr_sizes : [
	    {
		  outMinsize: 14,
		  outMaxsize: 16,
	      minWidth:320,
	      maxWidth:743,        
	      minSize:14,
	      maxSize:16
	    }
	],
	close_sizes : [
	    {
		  outMinsize: 32,
		  outMaxsize: 56,	    	
	      minWidth:320,
	      maxWidth:1136,        
	      minSize:32,
	      maxSize:56
	    }    
	],
	typo_sizes : [
	    {
	      outMinsize: 12,
	      outMaxsize: 14,	    	
	      minWidth:320,
	      maxWidth:743,        
	      minSize:12,
	      maxSize:14
	    }    
	],
	padding_sizes : [
	    {
	      outMinsize: 16,
	      outMaxsize: 32,	    	
	      minWidth:320,
	      maxWidth:1136,        
	      minSize:16,
	      maxSize:32
	    }    
	],
	btn_sizes : [
	    {
	      outMinsize: 14,
	      outMaxsize: 20,	    	
	      minWidth:320,
	      maxWidth:743,        
	      minSize:14,
	      maxSize:20
	    }    
	]					
}