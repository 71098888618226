export const RULES = {
	GANG_sizes : [
	    {
	      outMinsize: 60,
	      outMaxsize: 120,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:60,
	      maxSize:120
	    }    
	],
	digital_agency_sizes : [
	    {
	      outMinsize: 44,
	      outMaxsize: 80,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:44,
	      maxSize:80
	    }    
	  ],
	techinfo_sizes : [
	    {
	      outMinsize: 24,
	      outMaxsize: 40,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:24,
	      maxSize:40
	    }    
	],
	desc_sizes : [
	    {
	      outMinsize: 12,
	      outMaxsize: 16,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:12,
	      maxSize:16
	    }    
	],
	btntext_sizes : [
	    {
	      outMinsize: 16,
	      outMaxsize: 32,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:16,
	      maxSize:32
	    }    
	],
	btnraduis_sizes : [
	    {
	     outMinsize: 8,
	      outMaxsize: 16,	    	
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:8,
	      maxSize:16
	    }    
	]
}