import { cloneDeep, pick, omit } from 'lodash';
import { modalScheme } from '../../validationSchemes';
import { getModal } from './selectors';
import { postModal } from '../../api';

export const SET_OPEN = '@modal/SET_OPEN';
export const SET_OPENING = '@modal/SET_OPENING';
export const SET_DEFAULT = '@modal/SET_DEFAULT';
export const CHANGE_FIELD = '@modal/CHANGE_FIELD';
export const CHANGE_FIELD_ERROR = '@modal/CHANGE_FIELD_ERROR';

export const setOpen = async (dispatch) => { 
  dispatch({
    type: SET_OPEN
  });
}

export const setOpening = async (dispatch) => { 
  dispatch({
    type: SET_OPENING
  });
}

export const setDefault = async (dispatch) => { 
  dispatch({
    type: SET_DEFAULT
  });
}

export const changeField = (payload) => async (dispatch) => { 
  dispatch({
    type: CHANGE_FIELD,
    payload
  });
}

export const changeFieldError = ({ resolvedFields, rejectedFields }) => async (dispatch, getState) => {

	const state = getState();
	const modalState = cloneDeep(getModal(state));

	if (resolvedFields.length){
		resolvedFields.forEach(item=>{
			if (item in modalState){
				modalState[item].error = "";
			}
		});
	}

	if (rejectedFields.length){
		rejectedFields.forEach(item=>{
			if (item[0] in modalState){
				modalState[item[0]].error = item[1];
			}
		});
	}	

	dispatch({
		type: CHANGE_FIELD_ERROR,
		payload: modalState
	});
}


export const sendForm = async (dispatch, getState) => {
	try {		 
		const state = getState();
		const modalState = cloneDeep(getModal(state));
		const modalFields = await modalScheme.validate(Object.fromEntries(Object.entries(omit(modalState, ["state"])).map(([k, v])=>[k, v.value])),{abortEarly: false, stripUnknown: true});
		const result = await postModal(modalFields);
		dispatch(setDefault);
	} catch (errors) {			
		if (errors.name === "ValidationError"){			
			errors.inner.forEach((error) => {
				if (error.path) {
					dispatch(changeField({baseField: error.path , error: error.message}));
				}
			});
		} else if(errors.name === "ValidationResponse"){	
			// console.log(errors.resolvedFields);		
			console.log(errors.rejectedFields);		
			dispatch(changeFieldError(errors));
		} else {
			console.error(errors);
		}
	}
}