import { object, string, number, date, ref } from 'yup';
import { ValidationResponse } from '../errors';

let errorNameMessage = "";
const testName = "test-name";

function validateName(name, testContext){

	const nameNotCorrect = "Не корректное имя";
	const nameUnresolveLength = "Длина имени < 2 символов";

	let isEmpty = (name === "");
	let isUnresolvedSymbol = !/^[а-яА-ЯA-Za-z -]+$/.test(name);
	let isMinLength = name.length <= 2;
	if (isEmpty) throw new ValidationResponse({resolvedFields:[testContext.path]});

	if (isUnresolvedSymbol){
		throw new ValidationResponse({rejectedFields:[[testContext.path, nameNotCorrect]]});		
	} else if (isMinLength){
		throw new ValidationResponse({rejectedFields:[[testContext.path, nameUnresolveLength]]});
	} else {
		throw new ValidationResponse({resolvedFields:[testContext.path]});
	}

	throw new ValidationResponse({rejectedFields:[[testContext.path, "Что-то не так"]]});
}

export const nameScheme = object({
	name: string().test(testName, ()=>errorNameMessage, validateName),
});