export const postModal = async (fields) => {
  try{
    await fetch('https://mygang.ru/api/send-email',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        mode:'cors'        
      },
      body: JSON.stringify(fields)     
    });
  }catch(err){
    console.log(err);
  }
}