import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import styles from './Modal.module.scss';
import { Typography } from '../../ui/Typography';
import { Input } from '../../ui/Input';
import { Button } from '../../ui/Button';
import { Checkbox } from '../../ui/Checkbox';
import { changeField, changeFieldError, getModal, setOpen, setDefault, sendForm } from '../../store/modal';
import { phoneScheme, nameScheme, agreementScheme } from '../../validationSchemes';
import { RULES } from './rules';
import { useGetWidth } from '../../hooks/useGetWidth';
import { calcSize } from '../../utils/calcSize';

export const Modal = () => {

	const {
		questions_sizes,
		descr_sizes,
		close_sizes,
		typo_sizes,
		padding_sizes,
		btn_sizes} = RULES;

	const dispatch = useDispatch();
	const { name, phone, agreement, state} = useSelector(getModal);
	const width = useGetWidth();
	const questionSize = calcSize(questions_sizes, width);
	const descrSize = calcSize(descr_sizes, width);
	const closeSize = calcSize(close_sizes, width);
	const typoSize = calcSize(typo_sizes, width);
	const paddingSize = calcSize(padding_sizes, width);
	const btnSize = calcSize(btn_sizes, width);

	const closeStyle={
		width: `${closeSize}px`,
		height: `${closeSize}px`
	}
	
	useEffect(()=>{		
		return () => { dispatch(setDefault) };
	},[]);

	useEffect(()=>{
		if (state === "opening"){
			const timeoutId = setTimeout(()=>{dispatch(setOpen)}, 250);
			return () => { clearTimeout(timeoutId) };			
		}
	},[width]);
	
	return(
		<>
		{Boolean(state && state !== "closed") && (<div className={classnames(styles.overlay, {[styles["hide"]]:Boolean(state!=="opening")})} style={{padding: `${paddingSize}px`}}>
			<div className={styles.modal} style={{padding: `${paddingSize}px`}}>
				<div className={styles.close} onClick={()=>{dispatch(setDefault)}} style={closeStyle}></div>
				<Typography size={questionSize}>Есть вопросы? Мы всегда на связи</Typography>
				<div className={styles.bottom}>
					<Typography size={descrSize} style={{lineHeight: "21px"}}>
						Оставьте заявку на обратный звонок и в течение
						часа мы с вами свяжемся и ответим на все ваши
						вопросы
					</Typography>
					<div className={styles.wrap_all}>
						<div className={styles.wrap_inputs}>
							<Input
								type="text"
								placeholder="Телефон"												 
								value={phone.value}				
								setValue={(value)=>{dispatch(changeField({baseField: "phone", value: value}))}}
								validationScheme={(value)=>phoneScheme.validateSync({phone: value},{abortEarly: true, stripUnknown: true})}				
								showErrPerOneChange={true}
								errorMessage={phone.error}
								setErrorMessage={(value)=>{dispatch(changeFieldError(value))}}
							/>
							<Input
								type="text"
								placeholder="Имя"												 
								value={name.value}				
								setValue={(value)=>{dispatch(changeField({baseField: "name", value: value}))}}
								validationScheme={(value)=>nameScheme.validateSync({name: value},{abortEarly: true, stripUnknown: true})}				
								showErrPerOneChange={true}
								errorMessage={name.error}
								setErrorMessage={(value)=>{dispatch(changeFieldError(value))}}
							/>
						</div>
						<div className={styles.wrap_btns}>
							<Checkbox 
								checked={agreement.value}
								setStatus={(value)=>{dispatch(changeField({baseField: "agreement", value: value}))}}								
								errorMessage={agreement.error}
								label="Согласие на обработку персональных данных"
								setErrorMessage={(value)=>{dispatch(changeFieldError(value))}}
								typographySize={typoSize}
							/>
							<div className={styles.wrap_btn}>
								<Button
									text="Отправить"
									theme="light"
									cb={()=>{dispatch(sendForm)}}
									typography={{
										size:btnSize,
										weight: 700
									}}							
								/>								
							</div>

						</div>						
					</div>					
				</div>
			</div>			
		</div>)}
		</>
	);
}