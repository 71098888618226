import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';
import { Typography } from '../Typography';

export const Button = ({ text , theme, cb, btnStyles, typography }) => {
	return(
	    <div className={classnames(styles.btn, styles[theme])} style={btnStyles} onClick={cb}>
	      <Typography 
	        As="span"
	        color={theme === 'dark' ? 'white' : 'black'}         
	        font="montserrat"
	        {...typography}        
	      >
	        {text}
	      </Typography>
	    </div>
	);
}