import { object, string, number, date, ref } from 'yup';
import isMobilePhone from 'validator/lib/isMobilePhone.js';


const testName = "test-name";
let errorPhoneMessage = "";
let errorNameMessage = "";
let errorAgreementMessage = "";

function validateAgreement(agreement, testContext){

	const agreementRequery = "Согласие обязательно";

	if (agreement !== "checked"){
		errorAgreementMessage = agreementRequery;
	} else {
		return true;
	}
	return false;
}


function validateName(name, testContext){

	const nameNotCorrect = "Не корректное имя";
	const nameIsEmpty = "Заполните имя";
	const nameUnresolveLength = "Длина имени < 2 символов";

	let isEmpty = (name === "");
	let isUnresolvedSymbol = !/^[а-яА-ЯA-Za-z -]+$/.test(name);
	let isMinLength = name.length <= 2;
	
	if (isEmpty){
		errorNameMessage = nameIsEmpty;
	} else if (isUnresolvedSymbol){
		errorNameMessage = nameNotCorrect;		
	} else if (isMinLength){
		errorNameMessage = nameUnresolveLength;
	} else {
		return true;
	}

	return false;
	
}

function validatePhone(phonenumber, testContext){

	let phoneIsEmpty = "Заполните телефон";
	let phoneNotCorrect = "Не корректный телефон";
	let phoneMustNumber = "Телефон должен содержать цифры";
	let phoneNotWhitespace = "Телефон не должен содержать пробел";
	let isNotNumber = /[^\d\+]/.test(phonenumber);
	let isWhitespace = /\s/.test(phonenumber);	
	let isEmpty = (phonenumber === "");

	if (isEmpty){
		errorPhoneMessage = phoneIsEmpty;
	} else if (isWhitespace){
		errorPhoneMessage = phoneNotWhitespace;
	} else if (isNotNumber){
		errorPhoneMessage = phoneMustNumber;		
	} else if ((((/^[\d]+$/.test(phonenumber) && phonenumber.length >= 11) || (/^\+[\d]+$/.test(phonenumber) && phonenumber.length >= 12)) && !isMobilePhone(phonenumber, "ru-RU"))){
		errorPhoneMessage = phoneNotCorrect;		
	} else {
		return true;
	}

	return false;
}


export const modalScheme = object({
	agreement: string().test(testName, ()=>errorAgreementMessage, validateAgreement),
	name: string().test(testName, ()=>errorNameMessage, validateName),
	phone: string().test(testName, ()=>errorPhoneMessage, validatePhone)
});