export const RULES = {
	btn_sizes : [
	    {
		  outMinsize: 16,
		  outMaxsize: 32,	    	
	      minWidth:744,
	      maxWidth:1040,        
	      minSize:16,
	      maxSize:32
	    }    
	],	
	descr_sizes: [
	    {
		  outMaxsize: 530,
		  outMinsize: 530,
	      minWidth:744,
	      maxWidth:1136,        
	      minSize:337,
	      maxSize:530
	    },
	    {
		  outMaxsize: 530,
	      minWidth:320,
	      maxWidth:567,        
	      minSize:304,
	      maxSize:530
	    }	        
	]
}