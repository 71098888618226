import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import location from '../../assets/location.svg';
import { Typography } from '../../ui/Typography';
import styles from './Contacts.module.scss';
import { useGetWidth } from '../../hooks/useGetWidth';
import { calcSize } from '../../utils/calcSize';
import { VKontakte } from '../../assets/VKontakte';
import { Button } from '../../ui/Button';
import { useDispatch } from 'react-redux';
import { setOpening } from '../../store/modal';
import { RULES } from './rules';

export const Contacts = () => {

	const { btn_sizes, descr_sizes } = RULES;

	const width = useGetWidth();
	const dispatch = useDispatch();
    const defaultState = {
        center: [55.97645779415183, 92.85604814418026],
        zoom: 18,
    };

    const btnSize = calcSize(btn_sizes, width);
    const descrWidth = calcSize(descr_sizes, width);

	return(
		<div className="container">
			<div className={styles.wrap_all}>
				<Typography size={width < 1040? 24:120} color="white" style={{marginBottom: width < 1040? "16px":"40px"}}>Контакты</Typography>
				<div className={styles.wrap_screens}>
					<div className={styles.left_screen}>
						{Boolean(width < 1040) &&(<>
			              <Typography As="h2" size={20} color="white" bold>
			                Красноярск
			              </Typography>
			              <Typography  className={styles.whitespace} style={{width: `${descrWidth}px`}} size={14} color="white">
			                У нас есть уютный офис, приходите, обсудим ваш проект или побеседуем в свободной форме
			              </Typography>
						</>)}					
				        <YMaps>
				            <Map className={styles.map} defaultState={defaultState} options={{}}>
				                <Placemark
				                    options={{
				                        iconLayout: 'default#image',
				                        iconImageHref: location,
				                        iconImageSize: [44, 44],
				                        iconImageOffset: [-20, -40],
				                    }}
				                    geometry={[55.97644049869498,92.85602132209013]}
				                />
				            </Map>			            
				        </YMaps>
			            {Boolean(width < 1040 && width >= 744) && (<Button 
							text="Сотрудничать"
							theme="light"
							cb={()=>{dispatch(setOpening)}}
							btnStyles={{
								borderRadius: `8px`
							}}
							typography={{
								size:btnSize,
								weight: 700
							}}		            
			            />)}						
					</div>
					<div className={styles.right_screen}>
						{Boolean(width >= 1040) &&(<>
			              <Typography As="h2" size={40} color="white" bold>
			                Красноярск
			              </Typography>
			              <Typography  className={styles.whitespacetop}As="h2" size={16} color="white">
			                У нас есть уютный офис, приходите, обсудим ваш{'\n'}проект или побеседуем в свободной форме
			              </Typography>
						</>)}
		              <div className={styles.contact__body}>
		                <a className={styles.contact__item} href="https://2gis.ru/krasnoyarsk/firm/70000001037346580?m=92.8558%2C55.97631%2F18.49" target="_blank">
		                  <Typography As="h1" size={20} color="black" font="montserrat" bold>
		                    Красноярск
		                  </Typography>
		                  <div className={styles.contact__item__bottom}>		                    
		                      <Typography As="p" size={16} color="black" font="montserrat" style={{whiteSpace: "pre-wrap"}}>
		                        Афонтовский переулок,{'\n'} дом 2, офис 6-01, 6-04, 6-11
		                      </Typography>		                    
		                  </div>
		                </a>
		                <a className={styles.contact__item} href="mailto:welcome@mygang.ru">
		                  <Typography As="h1" size={20} color="black" font="montserrat" bold>
		                    Почта
		                  </Typography>
		                  <div className={styles.contact__item__bottom}>		                    
		                      <Typography As="p" size={16} color="black" font="montserrat">
		                        welcome@mygang.ru
		                      </Typography>		                    
		                  </div>
		                </a>
		                <a className={styles.contact__item} href="https://vk.com/gangagency" target="__blank">
		                  <Typography As="h1" size={20} color="black" font="montserrat" bold>
		                    Социальные сети
		                  </Typography>
		                  <div className={styles.contact__item__bottom}>		                    
		                      <VKontakte />		                    
		                  </div>
		                </a>
		                <a className={styles.contact__item} href="tel:+79960521560">
		                  <Typography As="h1" size={20} color="black" font="montserrat" bold>
		                    Телефон
		                  </Typography>
		                  <div className={styles.contact__item__bottom}>		                    
		                      <Typography As="p" size={16} color="black" font="montserrat">
		                        +7 (996) 052 15 60
		                      </Typography>		                    
		                  </div>
		                </a>
		              </div>		              						
			            {Boolean(width >= 1040 || width < 744) && (<Button 
							text="Сотрудничать"
							theme="light"
							cb={()=>{dispatch(setOpening)}}
							btnStyles={{
								borderRadius: `8px`
							}}
							typography={{
								size:btnSize,
								weight: 700
							}}		            
			            />)}
					</div>				
				</div>
			</div>
		</div>


	);
}



