export const calcSize = (rules, width) => {
	let rule;
	rule = rules.find(({minWidth, maxWidth})=>(width >= minWidth && width <= maxWidth));

	if (!rule && rules.length === 1){
		rule = rules[0];
	} else if(!rule){
		rules.sort((curr, next)=>curr.minWidth-next.minWidth);
		for(let i = 0; i < rules.length-1; i++){
			if (width > rules[i].maxWidth && width < rules[i+1].minWidth){
				rule = rules[i];
				break;
			} else if (i === 0 && width < rules[i].minWidth){
				rule = rules[i];
				break;
			} else if (i === rules.length-2 && width > rules[i+1].maxWidth){
				rule = rules[i+1];
				break;				
			}
		}
	}

	if (width >= rule.minWidth && width <= rule.maxWidth){
		return rule.minSize + (width - rule.minWidth) / ((rule.maxWidth - rule.minWidth) / (rule.maxSize - rule.minSize));
	} else if (width > rule.maxWidth){
		if (typeof rule.outMaxsize === 'number'){
			return rule.outMaxsize;
		} else {
			return rule.minSize + (width - rule.minWidth) / ((rule.maxWidth - rule.minWidth) / (rule.maxSize - rule.minSize));
		}
	} else if (width < rule.minWidth){
		if (typeof rule.outMinsize === 'number'){
			return rule.outMinsize;
		} else {
			return rule.minSize + (width - rule.minWidth) / ((rule.maxWidth - rule.minWidth) / (rule.maxSize - rule.minSize));
		}
	}

}

//example data
// 	rules= [
// 		{
//          outMinsize: 60, ?
//			outMaxsize: 120, ?
// 			minWidth:744,
// 			maxWidth:1136,        
// 			minSize:60,
// 			maxSize:120
// 		},
// 		{
//          outMinsize: 60, ?
//			outMaxsize: 120, ?
// 			minWidth:320,
// 			maxWidth:743,        
// 			minSize:60,
// 			maxSize:120
// 		} 		    
// 	]
