import React, { useEffect, useState } from 'react';
import { PreLoaderPage } from '../components/PreLoaderPage';
import { Header } from '../components/Header';
import { Promo } from '../components/Promo';
import { Modal } from '../components/Modal';
import { getState } from '../store/modal';
import { Contacts } from '../components/Contacts';
import { Footer } from '../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getBirdLoaded, setLoaded } from '../store/birdLoader';

export const Main = () => {
	const dispatch = useDispatch();
	const birdLoaded = useSelector(getBirdLoaded);
	const [preloaderState, setPreloaderState] = useState("show");
	const state = useSelector(getState);

	useEffect(() => {
		if (birdLoaded){
			setPreloaderState("fade");
		}		
	},[birdLoaded]);

	return(
		<>	
			{preloaderState !== "disappeared" && (<PreLoaderPage stateVis={preloaderState} cbState={setPreloaderState}/>)}
			<Header />
			<Promo />			
			<Contacts />						
			<Footer />	
			<Modal />
		</>

	);
}