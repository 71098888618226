import { object, string, number, date, ref } from 'yup';
import isMobilePhone from 'validator/lib/isMobilePhone.js';
import { ValidationResponse } from '../errors';

const testName = "test-name";
let errorPhoneMessage = "";


function validatePhone(phonenumber, testContext){

	let phoneNotCorrect = "Не корректный телефон";
	let phoneMustNumber = "Телефон должен содержать цифры";
	let phoneNotWhitespace = "Телефон не должен содержать пробел";
	let isNotNumber = /[^\d\+]/.test(phonenumber);
	let isWhitespace = /\s/.test(phonenumber);	
	let isEmpty = (phonenumber === "");

	if (isEmpty) throw new ValidationResponse({resolvedFields:[testContext.path]});

	if (isWhitespace){
		throw new ValidationResponse({rejectedFields:[[testContext.path, phoneNotWhitespace]]});
	} else if (isNotNumber){
		throw new ValidationResponse({rejectedFields:[[testContext.path, phoneMustNumber]]});		
	} else if ((((/^[\d]+$/.test(phonenumber) && phonenumber.length >= 11) || (/^\+[\d]+$/.test(phonenumber) && phonenumber.length >= 12)) && !isMobilePhone(phonenumber, "ru-RU"))){
		throw new ValidationResponse({rejectedFields:[[testContext.path, phoneNotCorrect]]});		
	} else {
		throw new ValidationResponse({resolvedFields:[testContext.path]});
	}

	throw new ValidationResponse({rejectedFields:[[testContext.path, "Что-то не так"]]});
}

export const phoneScheme = object({
	phone: string().test(testName, ()=>errorPhoneMessage, validatePhone)
});