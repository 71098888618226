import React from 'react';
import { useDispatch } from 'react-redux';
import { setOpening } from '../../store/modal';
import styles from './Footer.module.scss';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';
import { VKontakte } from '../../assets/VKontakte';
import { useGetWidth} from '../../hooks/useGetWidth';

export const Footer = () => {
	const dispatch = useDispatch();
	const width = useGetWidth();
	return(
		<div className="container" style={{padding: width<744?"0 8px 0 8px":"0 24px 0 24px"}}>
			<div className={styles.footer}>
				<div className={styles.row}>
					<div className={styles.col}>
						<div className={styles.logo}></div>
						<Button 
							text="Обсудить проект"
							theme="dark"
							cb={()=>{dispatch(setOpening)}}
							btnStyles={{
								borderRadius: `8px`
							}}
							typography={{
								size:20,
								weight: 700
							}}		            
			            />
					    <a href="#">
						    <Typography As="p" size={12} color="gray2">
						      Политика конфиденциальности
						    </Typography>
					    </a>			            						
					</div>
					<div className={styles.col}>
					    <Typography As="p" size={14} color="gray2">
					      Связаться
					    </Typography>
					    <div className={styles.grid}>
					      <div className={styles.flex}>
					        <a href="mailto:welcome@mygang.ru">
					          <Typography As="p" size={14} color="white">
					            welcome@mygang.ru
					          </Typography>
					        </a>
					        <a href="tel:+79960521560">
					          <Typography As="p" size={14} color="white">
					            +7 (996) 052 15 60
					          </Typography>
					        </a>
					      </div>
					      <a href="https://vk.com/gangagency" target="_blank">
					        <VKontakte type="white" />
					      </a>
					    </div>					    						
					</div>					
				</div>
				<div className={styles.bottom_row}>
					<div className={styles.bottom_row}>
			            <Typography As="p" size={14} color="gray2">
			              ООО “ГЭНГ”
			            </Typography>
			            <Typography As="p" size={14} color="gray2">
			              ОГРН 1212400015783
			            </Typography>
			            <Typography As="p" size={14} color="gray2">
			              ИНН 2464156107
			            </Typography>                        					
					</div>
		            <Typography As="p" style={{"textAlign": width<744?"left":"center", "width": "100%"}} size={14} color="gray2">
		              660078, Красноярский край, г Красноярск, пер Афонтовский, зд. 2, помещ. 75 (офис 611)
		            </Typography>
	            </div>				
			</div>
		</div>
	);
}
