import React, { useState } from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import styles from './Checkbox.module.sass';
import iconError from '../../assets/iconError.png';
import { Typography } from '../Typography';

export const Checkbox = ({checked, setStatus, setErrorMessage, errorMessage, label, typographySize}) => {

	const handleClick = (event) => {
		if (checked === "checked"){
			setStatus("");			
		} else {
			setStatus("checked");
			setErrorMessage({resolvedFields: ['agreement'], rejectedFields: []});
		}
	}	

	return(
			<div className={styles.wrap_checkbox} onClick={handleClick}>				
				<div className={classnames(styles.outer, {[styles["error"]]: Boolean(errorMessage)})}>
					<div className={classnames(styles.inner, {[styles["checked"]]:Boolean(checked)})}></div>
				</div>
				<Typography size={typographySize}>{label}</Typography>				
			</div>
	);
}