import { object, string, number, date, ref } from 'yup';
import { ValidationResponse } from '../errors';

let errorAgreementMessage = "";
const testName = "test-name";

function validateAgreement(agreement, testContext){
	const agreementRequery = "Согласие обязательно";
	if (agreement !== "checked"){
		throw new ValidationResponse({rejectedFields:[[testContext.path, agreementRequery]]});
	} else {
		throw new ValidationResponse({resolvedFields:[testContext.path]});
	}
}

export const agreementScheme = object({
	agreement: string().test(testName, ()=>errorAgreementMessage, validateAgreement)
});