import React from 'react';
import styles from './Promo.module.scss';
import classnames from 'classnames';
import { BirdModel } from '../../ui/BirdModel';
import { Typography } from '../../ui/Typography';
import { useGetWidth } from '../../hooks/useGetWidth';
import { calcSize } from '../../utils/calcSize';
import { Button } from '../../ui/Button';
import { RULES } from './rules';
import { useDispatch } from 'react-redux';
import { setOpening } from '../../store/modal';

export const Promo = () => {

	const width = useGetWidth();
	const dispatch = useDispatch();
	const {
		GANG_sizes,
		digital_agency_sizes,
		techinfo_sizes,
		desc_sizes,
		btntext_sizes,
		btnraduis_sizes } = RULES;				

	const gangSize = calcSize(GANG_sizes, width);
	const digitalAgencySize = calcSize(digital_agency_sizes, width);
	const techinfoSize = calcSize(techinfo_sizes, width);
	const descSize = calcSize(desc_sizes, width);
	const btntextSize = calcSize(btntext_sizes, width);
	const btnraduisSize = calcSize(btnraduis_sizes, width);
	

	return(
		<div className="container">
			<div className={classnames(styles.promo)}>
				<BirdModel />
				<div className={classnames(styles.rightblock)}>
		            <div className={styles.wrap_title}>
		              <Typography className={styles.up_text} As="span" size={digitalAgencySize} font="montserrat" color="gray">
		                digital
		              </Typography>
		              <Typography  className={styles.center_text} As="h1" size={gangSize} font="rostov" color="white">
		                GANG
		              </Typography>
		              <Typography className={styles.down_text} As="span" size={digitalAgencySize} font="montserrat" color="gray">
		                agency
		              </Typography>
		            </div>
		            <Typography className={styles.subtitle} As="h1" size={techinfoSize} font="montserrat" color="white">
		              Сайт находится
		              <br />
		              в разработке
		            </Typography>           
		            <Typography className={styles.desc} As="p" size={descSize} font="montserrat" color="white">
		                Мы понимаем, что у Вас может быть запрос на маркетинговые услуги, поэтому Вы можете оставить заявку и 
		                мы свяжемся с Вами в ближайшее время
		            </Typography> 
		            <Button 
						text="Сотрудничать"
						theme="light"
						cb={()=>{dispatch(setOpening)}}
						btnStyles={{
							borderRadius: `${btnraduisSize}px`
						}}
						typography={{
							size:btntextSize,
							weight: 700
						}}		            
		            />					
				</div>
			</div>
		</div>
	);
}
