import { useEffect, useState } from 'react';

export const useGetWidth = () => {
  const [width, setWidth] = useState(0);

 
  useEffect(() => {
 
    setWidth(window.innerWidth);    

    const resizeHandler = (event) => {
      if (event.type === "resize"){
        setWidth(window.innerWidth); 
      }
    }

    window.addEventListener("resize", resizeHandler);
    return ()=>{window.removeEventListener("resize", resizeHandler);}

  }, [width]);

  return width;
}