import React from 'react';
import styles from './Header.module.scss';
import classnames from 'classnames';


export const Header = () => {
	return(
		<div className={classnames(styles.header)}>
			<div className={classnames(styles.logo_container)}></div>
		</div>
	);
}

