import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import { modalReducer } from './modal';
import { birdLoaderReducer } from './birdLoader';

const reducer = combineReducers({
  modal: modalReducer,
  birdLoader: birdLoaderReducer
});

const composeEnhancers = composeWithDevTools({ name: 'MyGang' });
export const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

