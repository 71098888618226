import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Main } from './pages/Main';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(   
    <Provider store={store}>
      <Router>      
        <Routes>          
          <Route path="/" element={<Main/>}/>
          <Route path="*" element={<Navigate to="/" replace={true} />}/>        
        </Routes>
      </Router>
    </Provider>
);

