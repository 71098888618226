import {
	SET_OPEN,
	SET_OPENING,
	SET_DEFAULT,
	CHANGE_FIELD,
	CHANGE_FIELD_ERROR
} from './actions';


export const initialState = {
	name: {
		value: "",
		error: ""
	},
	phone: {
		value: "",
		error: ""
	},
	agreement: {
		value: "",
		error: ""
	},	
	state: "closed" // closed opened opening
}


export const modalReducer = (state = initialState, action) => {
  switch (action.type) { 
    case SET_OPEN: 
      return {
        ...state, state: "opened"
      };
    case SET_OPENING:
    	return{
    		...state, state: "opening"
    	};
    case CHANGE_FIELD:
      return {
        ...state, [action.payload.baseField]: {...state[action.payload.baseField], [Object.keys(action.payload)[1]]: Object.values(action.payload)[1]}        
      }; 
    case CHANGE_FIELD_ERROR:
      return action.payload; 
    case SET_DEFAULT:
      return {...initialState };                                 
    default:
      return state;
  }
};