import React, { useEffect } from 'react';
import classnames from 'classnames';
import styles from './PreLoaderPage.module.scss';

export const PreLoaderPage = ({ stateVis, cbState }) => {
	useEffect(()=>{
		if (stateVis === "fade"){
			setTimeout(()=>{cbState("disappeared")},2000);
		}

	},[stateVis, cbState]);
	return (
		<div className={classnames(styles.preloader, {[styles['fade']]:stateVis==="fade"})}></div>
	);
}